var ui_scroll = (function(document) {

	var evt = [

		function(document) {

			let oldValue;

			window.addEventListener('scroll', function(e){

				var newValue = window.pageYOffset;
				var header = document.querySelector('header');
		
				if(oldValue - newValue < 0){
					
					header.classList.add('scrollDown');

				} else if(oldValue - newValue > 0){
					
					header.classList.remove('scrollDown');
				}
		
				oldValue = newValue;
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

ui_scroll.init();
