var ui_slider_thumb = (function(document) {

	var evt = [

		function(document) {

			var swiperGalleries = document.querySelectorAll('.gallery-slider');

			swiperGalleries.forEach(function(swiperGallery){

				var swiperTops = swiperGallery.querySelector('.swiper-top');
				var swiperBottoms = swiperGallery.querySelector('.swiper-thumb');
				var next = swiperTops.querySelector('.swiper-button-next');
				var prev = swiperTops.querySelector('.swiper-button-prev');
				var pagination = swiperTops.querySelector('.swiper-pagination');

				var swiperTop = new Swiper(swiperTops, {
					spaceBetween: 0,
					slidesPerView: 1,
					loop: true,
					loopedSlides: 4,
					pagination: {
						el: pagination,
						clickable: true
					},
					navigation: {
						nextEl: next,
						prevEl: prev,
					},
				});
	
				var swiperThumb = new Swiper(swiperBottoms, {
					spaceBetween: 0,
					slidesPerView: 1,
					loop: true,
					centeredSlides: false,
					slideToClickedSlide: true,
					touchRatio: 0.2,
					loopedSlides: 4,
					autoHeight: true,
					breakpoints: {
						640: {
							slidesPerView: 1,
							autoHeight: true,
						},
						768: {
							slidesPerView: 1,
							autoHeight: true,
						},
						991: {
							slidesPerView: 1,
							autoHeight: true,
						},
						1024: {
							slidesPerView: 1,
							autoHeight: false,
						},
					},
				});
	
				swiperTop.controller.control = swiperThumb;
				swiperThumb.controller.control = swiperTop;

			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

document.addEventListener('DOMContentLoaded', function(event) {
  ui_slider_thumb.init();
});