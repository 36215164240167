/* JavaScript File                                                  */
/* event-hashtag.js                                                 */
/* Modified Oct 1, 2020                                             */


var event_hashtag = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('a[href*="#"]:not([role="tab"])');

			toggle.forEach(function(button){

				button.addEventListener('click', function(e){
					e.preventDefault();

					if(button.href.split('#')[1] != '') {
						
						if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') || location.hostname === this.hostname) {

							var href = button.getAttribute('href');
							var offsetTop = document.querySelector(href).offsetTop;

							window.scroll({
								top: offsetTop,
								behavior: 'smooth'
							});	
							
						}

					}
					

				}, false);
			});
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

event_hashtag.init();