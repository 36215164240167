/* JavaScript File                                                  */
/* accordion.js                                                   */
/* Modified Feb 09, 2022                                           */


var accordion = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[data-toggle=accordion]');

			toggle.forEach(function(accord) {

				var target = accord.nextElementSibling;

				var close = function(){

					var panels = document.querySelectorAll('.' + accord.getAttribute('aria-controls'));

					panels.forEach(function(panel){

						panel.classList.remove('open');
					});

					toggle.forEach(function(accord){

						accord.classList.remove('open');
					});
				}

				accord.addEventListener('click', function (event) {

					if(accord.classList.contains('open')) {

						close();

					} else {

						close();
						accord.classList.add('open');
						target.classList.add('open');
					}

				}, false);
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

accordion.init();
