var counted = (function(document) {

	var evt = [

		function(document) {

			const counters = document.querySelectorAll('.counting');
			const speed = 1000;

			counters.forEach( counter => {
				const animate = () => {
						const value = +counter.getAttribute('akhi');
						const data = +counter.innerText;
					
						const time = value / speed;
					if(data < value) {
							counter.innerText = Math.ceil(data + time);
							setTimeout(animate, 1);
						}else{
							counter.innerText = value;
						}
					
				}

				appear({
					elements: function elements(){
	
						return document.getElementsByClassName('count');
					},
					appear: function appear(coverDown){
						
						animate();
	
					},
					
					bounds: 200,
					reappear: false
				});
				
				
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

counted.init();