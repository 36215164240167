/* JavaScript File                                                  */
/* ui-tabs.js                                                  		*/
/* Modified June 11, 2022                                        */


var datepicker = (function(document) {

	var evt = [

		// tabs - function to control a tabbed interface

		function(document, window, args) {

			const getDatePickerTitle = elem => {
				// From the label or the aria-label
				const label = elem.nextElementSibling;
				let titleText = '';
				if (label && label.tagName === 'LABEL') {
					titleText = label.textContent;
				} else {
					titleText = elem.getAttribute('aria-label') || '';
				}
				return titleText;
			}

			const elems = document.querySelectorAll('.datepicker_inline');
			var dateField = document.querySelector('.datepicker_value');
			for (const elem of elems) {
				const datepicker = new Datepicker(elem, {
					'format': 'dd/mm/yyyy', // UK format
					title: getDatePickerTitle(elem)
				});

				elem.addEventListener('click', function(){
					
					if(dateField) {

						dateField.value = datepicker.getDate('dd/mm/yyyy');
					}
					
				});

			} 
		}

	],
	initAll = function(args) {

		initEvt(document, window, args);
	},
	initEvt = function(document, window, args) {

		evt.forEach(function(e) {
			
			e(document, window, args);
		});
	};
	
	return { init: initAll };

})(document, window);

datepicker.init();